import React, { Suspense, lazy, useEffect } from "react";
import './App.css';
import { BrowserRouter, Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import Loader from "./app-dt-sell/components/loader";
import { Provider } from 'react-redux';
import store from './app-dt-sell/store';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { COLOR } from "./app-dt-sell/common/constant";

const Login = lazy(() => import("./app-dt-sell/auth/login"));
const Dashboard = lazy(() => import("./app-dt-sell/dashboard"));
const Company = lazy(() => import("./app-dt-sell/company"));
const Branch = lazy(() => import("./app-dt-sell/branch-details/branch"));
const Party = lazy(() => import("./app-dt-sell/party-details/party"));
const PartyDetails = lazy(() => import("./app-dt-sell/party-details/party-details"));
const Employee = lazy(() => import("./app-dt-sell/employee"));
const Inventory = lazy(() => import("./app-dt-sell/inventory"));
const Layout = lazy(() => import("./app-dt-sell/auth/layout"));
const NotFound = lazy(() => import("./app-dt-sell/components/NotFound"));

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR.primary
    }
  }
});

function App() {
  if (window.location.pathname?.includes('erp.dholakia.tech')) {
    console.log = function() {};
  } else {
    const originalConsoleLog = console.log;
    let isConsoleLogEnabled = true;
  
    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'L') {
        isConsoleLogEnabled = !isConsoleLogEnabled;
  
        if (isConsoleLogEnabled) {
          console.log = originalConsoleLog;
          console.log("Console logs are now enabled.");
        } else {
          console.log = function() {};
          originalConsoleLog("Console logs are now disabled.");
        }
      }
    });
  }
  
  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<AuthRoute><Login /></AuthRoute>} />
                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
                  <Route path="/company" element={<Layout><Company type_id={0} form_name="Company" /></Layout>} />
                  <Route path="/branch" element={<Layout><Branch type_id={0} form_name="Branch" /></Layout>} />
                  <Route path="/party" element={<Layout><Party type_id={0} form_name="Party" /></Layout>} />
                  <Route path="/party/details" element={<Layout><PartyDetails type_id={0} form_name="Party"/></Layout>} />
                  <Route path="/employee" element={<Layout><Employee type_id={0} form_name="Employee" /></Layout>} />
                  <Route path="/Inventory" element={<Layout><Inventory type_id={0} form_name="Inventory" /></Layout>} />
                </Route>

                {/* Catch-All Route */}
                <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Suspense>
    </React.Fragment>
  );
}

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userAuth");

  useEffect(() => {
    if (!token) {
      navigate("/"); 
    }
  }, [token, navigate]);

  if (!token) {
    return null; 
  }

  return children ? children : <Outlet />; 
};

const AuthRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userAuth");

  useEffect(() => {
    if (token) {
      navigate("/dashboard"); 
    } else {
      navigate("/");
    }
  }, [token, navigate]);

  if (token) {
    return null; 
  }

  return children; 
};

export default App;
